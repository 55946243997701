import React, { FC, useEffect, useRef, useState } from 'react';
import { Select, Table } from 'antd';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERoute } from 'common/const/route.enum';
import { InfiniteScrollContainer } from 'common/components/InfiniteScrollContainer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { useCatalogContext } from 'common/hooks/useCatalogContext';
import { useGoodsContext } from 'common/hooks/useGoodsContext';
import { usePropertyContext } from 'common/hooks/usePropertyContext';
import { LIST_LIMIT_0, DEFAULT_LIST_OFFSET, DEFAULT_PAGINATION_PAGE, LIST_LIMIT_20 } from 'common/config';
import { stringToNumber } from 'common/helpers/common.helper';
import { RootDispatch, RootState } from 'app/store';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { CatalogBreadcrumb } from 'entities/Catalog/components/CatalogBreadcrumb';
import { CatalogCategoryList } from 'entities/Catalog/components/CatalogCategoryList';
import { CatalogEmpty } from 'entities/Catalog/components/CatalogEmpty';
import { CatalogFilterChips } from 'entities/Catalog/components/CatalogFilterChips';
import {
  renderCatalogGoodsListRecords,
  searchParamsToCatalogGoodsListPositions,
  getCatalogEmptyStateContent,
} from 'entities/Catalog/Catalog.helper';
import { catalogGoodsAvailabilityFilterOptions } from 'entities/Catalog/Catalog.const';
import { IGoodsListPayload } from 'entities/Goods/Goods.models';

interface IComponentProps {
  catalogId?: string;
  catalogName?: string;
  categoryId: number;
  catalogListLoading: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CatalogCategoryPageComponent: FC<AllType> = (props) => {
  const {
    // props
    catalogId,
    catalogName,
    categoryId,
    catalogListLoading,
    // state
    categoryList,
    goodsList,
    goodsListLoading,
    goodsListCount,
    propertyList,
    category,
    goodsLoading,
    // dispatch
    setCategoryList,
    getCategoryList,
    setGoodsList,
    getGoodsList,
    getGoodsListPart,
    setPropertyList,
    getPropertyListByCategoryId,
    setCategory,
    getCategoryById,
  } = props;

  const [goodsListPayload, setGoodsListPayload] = useState<IGoodsListPayload | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const goodsListFilterChipsRef = useRef<HTMLDivElement | null>(null);
  const { page, scrollUp, fetchLoading, setPage, setScrollDirection, setFetchLoading } = useCatalogContext();
  const { onOpenGoodsCard } = useGoodsContext();
  const { propertiesForView } = usePropertyContext();

  const categoryListLoading = catalogListLoading || fetchLoading;
  const showEmptyState = !categoryListLoading && !goodsListLoading && !goodsList.length && !!goodsListPayload;
  const goodsListFilterChipsHeight = goodsListFilterChipsRef.current?.clientHeight || 0;
  const goodsListColumns = renderCatalogGoodsListRecords({ category });
  const { emptyTitle, emptyDescription, emptyBackBtnTitle } = getCatalogEmptyStateContent({
    payload: goodsListPayload,
    categoryId,
  });

  const onGoodsListAvailabilityFilterChange = (value: boolean) => {
    setPage(DEFAULT_PAGINATION_PAGE);
    setGoodsListPayload((prev) => ({ ...prev, isAvailableForPurchase: value ? value : undefined }));
  };

  const onCatalogEmptyPageBackClick = () => {
    if (catalogId) {
      if (category?.parentId) {
        navigate(`${ERoute.Catalog}/${catalogId}/${category.parentId}`);
      } else {
        navigate(`${ERoute.Catalog}/${catalogId}`);
      }
    }
  };

  useEffect(() => {
    if (goodsListPayload) {
      getGoodsList(goodsListPayload);
    }
  }, [goodsListPayload]);

  useEffect(() => {
    if (category && categoryId === category.id) {
      setPage(DEFAULT_PAGINATION_PAGE);
      setGoodsListPayload((prev) => ({
        ...prev,
        categoryId: category.id,
        search: undefined,
        isAvailableForPurchase: undefined,
        positions: searchParamsToCatalogGoodsListPositions(propertyList),
        offset: DEFAULT_LIST_OFFSET,
        limit: LIST_LIMIT_20,
      }));
    }
  }, [location, categoryId, category, propertyList]);

  useEffect(() => {
    const fetch = async () => {
      setGoodsList([]);
      setPage(DEFAULT_PAGINATION_PAGE);
      setFetchLoading(true);

      if (catalogId && categoryId) {
        await getCategoryList({
          catalogId: stringToNumber(catalogId),
          withoutParents: false,
          parentId: categoryId,
          limit: LIST_LIMIT_0,
        });
        await getPropertyListByCategoryId(categoryId);
        await getCategoryById(categoryId);
      }

      setFetchLoading(false);
    };

    fetch();
  }, [catalogId, categoryId]);

  useEffect(() => {
    return () => {
      setFetchLoading(true);
      setCategory(null);
      setCategoryList([]);
      setGoodsList([]);
      setPropertyList([]);
    };
  }, []);

  return (
    <InfiniteScrollContainer
      canLoad={!goodsListLoading && goodsList.length < goodsListCount}
      scrollToTopTrigger={[categoryList, goodsListPayload, propertiesForView]}
      onLoad={() => {
        if (goodsListPayload) {
          setPage(page + 1);
          getGoodsListPart({ ...goodsListPayload, offset: LIST_LIMIT_20 * page });
        }
      }}
      setScrollDirection={setScrollDirection}
    >
      <CatalogBreadcrumb catalogId={catalogId} catalogName={catalogName} category={category} className="catalog-breadcrumb" />

      {category && <span className="text-h1 catalog__title">{category.name}</span>}

      <CatalogCategoryList loading={categoryListLoading} collapsible={false} categoryList={categoryList} />

      <div
        className="catalog-goods-list-filter-chips"
        style={{ top: scrollUp ? 0 : -goodsListFilterChipsHeight }}
        ref={goodsListFilterChipsRef}
      >
        <Select
          rootClassName="redesign"
          value={!!goodsListPayload?.isAvailableForPurchase}
          onChange={onGoodsListAvailabilityFilterChange}
          options={catalogGoodsAvailabilityFilterOptions}
          popupMatchSelectWidth={false}
          suffixIcon={<ArrowDownShortIcon className="icon-arrow-down-short-bright-green" />}
        />

        <CatalogFilterChips />
      </div>

      <Table
        className={`
          table-hover
          catalog-goods-list
          ${!!goodsList.length && 'catalog-goods-list-full-screen'}
          ${!!goodsList.length && goodsList.length === goodsListCount && 'catalog-goods-list-end-of-list'}
          ${!goodsList.length && 'catalog-goods-list-empty'}
        `}
        dataSource={goodsList.map((goods) => ({ key: goods.id, ...goods }))}
        columns={goodsListColumns}
        pagination={false}
        loading={{ spinning: fetchLoading || goodsListLoading || goodsLoading, indicator: <SpinIndicator /> }}
        showHeader={!!goodsList.length}
        onRow={({ id, goodInBasket }) => ({ onClick: () => onOpenGoodsCard(id, goodInBasket) })}
        onHeaderRow={() => ({
          className: 'catalog-goods-list-header',
          style: { top: scrollUp ? goodsListFilterChipsHeight : 0 },
        })}
        locale={{
          emptyText: (
            <CatalogEmpty
              open={showEmptyState}
              title={emptyTitle}
              description={emptyDescription}
              btnTitle={emptyBackBtnTitle}
              onBack={onCatalogEmptyPageBackClick}
            />
          ),
        }}
      />
    </InfiniteScrollContainer>
  );
};

const mapState = (state: RootState) => ({
  categoryList: state.categoryListState.data,
  goodsList: state.goodsListState.data,
  goodsListLoading: state.goodsListState.loading,
  goodsListCount: state.goodsListState.count,
  propertyList: state.propertyListState.data,
  category: state.categoryState.data,
  goodsLoading: state.goodsState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  setCategoryList: dispatch.categoryListState.setCategoryList,
  getCategoryList: dispatch.categoryListState.getCategoryList,
  setGoodsList: dispatch.goodsListState.setGoodsList,
  getGoodsList: dispatch.goodsListState.getGoodsList,
  getGoodsListPart: dispatch.goodsListState.getGoodsListPart,
  setPropertyList: dispatch.propertyListState.setPropertyList,
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  setCategory: dispatch.categoryState.setCategory,
  getCategoryById: dispatch.categoryState.getCategoryById,
});

export const CatalogCategoryPage = connect(mapState, mapDispatch)(CatalogCategoryPageComponent);
