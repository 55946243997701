import React, { ChangeEvent, FC, useEffect, useState, MouseEvent } from 'react';
import { Button, Drawer, Input, Image } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { priceFormatter } from 'common/helpers/formatter.helper';
import { showSuccessMessage } from 'common/helpers/message.helper';
import { useDebounce } from 'common/hooks/useDebounce';
import { Counter } from 'common/components/Counter';
import { DeliveryBadge } from 'common/components/DeliveryBadge';
import { EMessage } from 'common/const/message.enum';
import { ERoute } from 'common/const/route.enum';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { COUNT_CHANGE_DELAY, DRAWER_Z_INDEX_10000 } from 'common/config';
import { useGoodsContext } from 'common/hooks/useGoodsContext';
import { ReactComponent as CloseIcon } from 'app/assets/images/redesign/close.svg';
import { ReactComponent as CopyIcon } from 'app/assets/images/redesign/copy.svg';
import placeholder from 'app/assets/images/redesign/placeholder.svg';
import { RootDispatch, RootState } from 'app/store';
import { getUserRole } from 'entities/User/User.helper';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const Component: FC<AllType> = (props) => {
  const {
    // state
    xBasketId,
    auth,
    currentUser,
    goods,
    currentBasket,
    basketLoading,
    // dispatch
    updateGoodsListGoodsInBasket,
    setGoods,
    updateGoods,
    updateBasket,
    getUserStatistics,
  } = props;

  const [buyerCodeIsFocused, setBuyerCodeIsFocused] = useState<boolean>(false);
  const [buyerCodeIsChanged, setBuyerCodeIsChanged] = useState<boolean>(false);
  const [countIsChanged, setCountIsChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const { count, openGoodsCard, setCount, setOpenGoodsCard } = useGoodsContext();

  const { isSellerManager } = getUserRole(currentUser?.roles);
  const isSeller = auth?.access.isSeller;
  const goodsId = goods?.id;
  const buyerCode = goods?.buyerCode;
  const showAddGoodsToBasketBtn = !isSellerManager || (isSellerManager && xBasketId);

  const onVendorCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (goods) {
      setBuyerCodeIsChanged(true);
      setGoods({ ...goods, buyerCode: e.target.value });
    }
  };

  const onVendorCodeCopy = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (buyerCode) {
      navigator.clipboard.writeText(buyerCode);
    }
  };

  const handleCategoryClick = (id: number, catalogId: number) => {
    setOpenGoodsCard(false);
    navigate(`${ERoute.Catalog}/${catalogId}/${id}`);
  };

  const addGoodsToBasket = (id: number) => {
    if (currentBasket) {
      const newBasketGoods = [
        ...currentBasket.goods.map((basketGoods) => ({ goodId: basketGoods.goodId, count: basketGoods.count })),
        { goodId: id, count },
      ];

      updateBasket({
        id: currentBasket.id,
        goods: newBasketGoods,
        onSuccess: () => {
          if (goods) {
            setGoods({ ...goods, goodInBasket: true });
          }

          updateGoodsListGoodsInBasket({ id, goodInBasket: true });
          showSuccessMessage(EMessage.GoodsAddedToCart);
          getUserStatistics();
        },
      });
    }
  };

  const goToBasket = () => {
    if (xBasketId) {
      navigate(`/active-basket-list/${xBasketId}`);
    } else {
      navigate(ERoute.Basket);
    }
  };

  const onCountChange = (value: number, goodInBasket: boolean) => {
    if (goodInBasket) {
      setCountIsChanged(true);
    }

    setCount(value);
  };

  useEffect(() => {
    if (!openGoodsCard) {
      setBuyerCodeIsChanged(false);
    }
  }, [openGoodsCard]);

  useDebounce(() => {
    if (goodsId && buyerCodeIsChanged) {
      updateGoods({ id: goodsId, buyerCode: buyerCode ? buyerCode : null });
    }
  }, [buyerCodeIsChanged, buyerCode]);

  useDebounce(
    () => {
      if (goodsId && currentBasket && countIsChanged) {
        updateBasket({
          id: currentBasket.id,
          goods: currentBasket.goods.map((basketGoods) => {
            if (basketGoods.goodId === goodsId) {
              return { ...basketGoods, count };
            }

            return basketGoods;
          }),
          onSuccess: () => setCountIsChanged(false),
        });
      }
    },
    [goodsId, currentBasket, countIsChanged, count],
    COUNT_CHANGE_DELAY,
  );

  if (!goods) {
    return null;
  }

  return (
    <Drawer
      rootClassName="redesign drawer goods-card"
      open={openGoodsCard}
      onClose={() => setOpenGoodsCard(false)}
      width={800}
      destroyOnClose
      zIndex={DRAWER_Z_INDEX_10000}
    >
      <div className="goods-card__header">
        <span className="text-body color-dark-grey">{goods.sellerCode}</span>

        <span className="text-h4 color-bright-green">{goods.brand}</span>
      </div>

      <div className="drawer__title">{goods.name}</div>

      <div className="goods-card__labels-container">
        <DeliveryBadge remains={goods.remains} count={count} />
      </div>

      <div className="goods-card__price">
        <span className="text-h2">{priceFormatter(goods.priceWithTaxes)}</span>

        <span className="text-controls color-light-grey"> / </span>

        <span className="text-controls color-dark-grey">шт, включая НДС</span>
      </div>

      <div className="goods-card__actions-container">
        {!isSeller && (
          <div className="input-with-label" style={{ flex: 1 }}>
            <span className="text-tag input-with-label__label">Артикул клиента</span>

            <Input
              className="input-with-value"
              value={goods.buyerCode}
              onChange={onVendorCodeChange}
              placeholder={EPlaceholder.AddVendorCode}
              onFocus={() => setBuyerCodeIsFocused(true)}
              onBlur={() => setBuyerCodeIsFocused(false)}
              allowClear={buyerCodeIsFocused && { clearIcon: <CloseIcon className="icon-close-dark-grey" /> }}
              suffix={
                <span style={{ lineHeight: 0 }}>
                  {!!buyerCode?.length && !buyerCodeIsFocused && (
                    <Button
                      className="button-icon"
                      icon={<CopyIcon className="icon-copy-dark-grey" />}
                      onClick={onVendorCodeCopy}
                    />
                  )}
                </span>
              }
            />
          </div>
        )}

        {showAddGoodsToBasketBtn && <Counter count={count} onChange={(value) => onCountChange(value, goods.goodInBasket)} />}
      </div>

      {showAddGoodsToBasketBtn && (
        <div className="goods-card__btn">
          {goods.goodInBasket ? (
            <Button className="button-l success w-100" onClick={goToBasket}>
              Перейти в корзину
            </Button>
          ) : (
            <Button className="button-l primary w-100" onClick={() => addGoodsToBasket(goods.id)} loading={basketLoading}>
              Добавить в корзину
            </Button>
          )}
        </div>
      )}

      <Image wrapperClassName="image-640 mb-72" src={goods.image ? goods.image : placeholder} preview={false} />

      <div className="goods-card__block">
        <div className="text-h4 goods-card__block-title">Характеристики</div>

        <div className="goods-card__block-list">
          {goods.properties.map((property) => {
            return (
              <div key={property.propertyId} className="goods-card__block-list-row">
                <span className="text-body color-dark-grey">{`${property.propertyName}:`}</span>

                <span className="text-body">{`${property.value} ${property.unitOfMeasurement || ''}`}</span>
              </div>
            );
          })}
        </div>
      </div>

      {goods.categories && (
        <div className="goods-card__block">
          <div className="text-h4 goods-card__block-title">Категории с товаром</div>

          {goods.categories.map(({ categoryId, categoryName, catalogId }) => {
            return (
              <Button
                key={categoryId}
                className="button-chip"
                onClick={() => handleCategoryClick(categoryId, catalogId)}
                style={{ pointerEvents: !catalogId ? 'none' : 'auto', marginRight: 10 }}
              >
                {categoryName}
              </Button>
            );
          })}
        </div>
      )}
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  xBasketId: state.authState.xBasketId,
  auth: state.authState.data,
  currentUser: state.userState.currentUser,
  goods: state.goodsState.data,
  currentBasket: state.basketState.currentBasket,
  basketLoading: state.basketState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  updateGoodsListGoodsInBasket: dispatch.goodsListState.updateGoodsListGoodsInBasket,
  setGoods: dispatch.goodsState.setGoods,
  updateGoods: dispatch.goodsState.updateGoods,
  updateBasket: dispatch.basketState.updateBasket,
  getUserStatistics: dispatch.statisticsState.getUserStatistics,
});

export const GoodsCard = connect(mapState, mapDispatch)(Component);
