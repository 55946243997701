import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { EPlaceholder } from 'common/const/placeholder.enum';
import { mapToMenuWithDivider } from 'common/helpers/common.helper';
import { SELECT_LIST_HEIGHT_210 } from 'common/config';
import { ReactComponent as ArrowDownShortIcon } from 'app/assets/images/redesign/arrow-down-short.svg';
import { IProperty } from 'entities/Property/Property.models';

interface IComponentProps {
  property: IProperty;
  fieldClassName?: string;
  popupClassName?: string;
  mode?: 'multiple' | 'tags';
  rules?: Rule[];
}

export const PropertySelect: FC<IComponentProps> = ({ property, fieldClassName, mode, rules }) => {
  const { id, displayName, unitOfMeasurement, values } = property;
  const unit = unitOfMeasurement ? `, ${unitOfMeasurement}` : '';
  const label = rules?.length ? `${displayName}${unit}*` : `${displayName}${unit}`;

  return (
    <Form.Item className={fieldClassName} label={label} name={id} rules={rules}>
      <Select
        rootClassName="redesign"
        mode={mode}
        placeholder={EPlaceholder.Select}
        suffixIcon={<ArrowDownShortIcon />}
        listHeight={SELECT_LIST_HEIGHT_210}
        virtual={false}
        showSearch={false}
        maxTagCount="responsive"
        maxTagPlaceholder={(omittedValues) => <span>{`+${omittedValues.length}...`}</span>}
        tagRender={({ label }) => <span className="text-controls-accent">{label}</span>}
        options={mapToMenuWithDivider(values.map(({ value }) => ({ label: value, value: value })))}
        disabled={property.isCategory}
      />
    </Form.Item>
  );
};
