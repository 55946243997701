import React, { FC, useState } from 'react';
import { Button, Drawer, Dropdown, Spin } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WarningDrawer } from 'common/components/WarningDrawer';
import { SpinIndicator } from 'common/components/SpinIndicator';
import { Image } from 'common/components/Image';
import { InventoryCount } from 'common/components/InventoryCount';
import { EPresetType } from 'common/const/preset.enum';
import { ERoute } from 'common/const/route.enum';
import { EMessage } from 'common/const/message.enum';
import { showMessage, showSuccessMessage } from 'common/helpers/message.helper';
import { countFormatter, priceFormatter } from 'common/helpers/formatter.helper';
import { useWorkspaceContext } from 'common/hooks/useWorkspaceContext';
import { getPresetRangeValue } from 'common/helpers/preset.helper';
import { calculateDeliveryTime } from 'common/helpers/delivery.helper';
import { DEFAULT_GOODS_COUNT, ITEMS_FOR_VIEW_LIMIT_4 } from 'common/config';
import { ReactComponent as OptionsIcon } from 'app/assets/images/redesign/options.svg';
import { ReactComponent as BinIcon } from 'app/assets/images/redesign/bin.svg';
import { ReactComponent as CartIcon } from 'app/assets/images/redesign/cart.svg';
import { RootDispatch, RootState } from 'app/store';
import { WorkspacePositionInventoryLimit } from 'entities/Workspace/components/WorkspacePositionInventoryLimit';

interface IComponentProps {
  editable: boolean;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const Component: FC<AllType> = (props) => {
  const {
    // props
    editable,
    // state
    propertyListLoading,
    goodsList,
    workspacePosition,
    workspacePositionLoading,
    currentBasket,
    basketLoading,
    // dispatch
    getPropertyListByCategoryId,
    updateGoodsListGoodsInBasket,
    deleteWorkspacePosition,
    updateBasket,
    getUserStatistics,
  } = props;

  const [openWarningCard, setOpenWarningCard] = useState<boolean>(false);
  const [showAllProperties, setShowAllProperties] = useState<boolean>(false);
  const navigate = useNavigate();
  const { openPositionCard, setOpenPositionCard, setOpenEditPositionCard } = useWorkspaceContext();

  const propertiesForView = showAllProperties
    ? workspacePosition?.properties
    : workspacePosition?.properties.slice(0, ITEMS_FOR_VIEW_LIMIT_4);

  const onEditClick = async () => {
    if (workspacePosition) {
      await getPropertyListByCategoryId(workspacePosition.categoryId);
      setOpenPositionCard(false);
      setOpenEditPositionCard(true);
    }
  };

  // eslint-disable-next-line
  const onCopyClick = async () => {
    if (workspacePosition) {
      await getPropertyListByCategoryId(workspacePosition.categoryId);
      setOpenPositionCard(false);
      setOpenEditPositionCard(true);
    }
  };

  const onDeleteClick = () => {
    setOpenPositionCard(false);
    setOpenWarningCard(true);
  };

  const onDeleteConfirm = () => {
    if (workspacePosition) {
      deleteWorkspacePosition({
        id: workspacePosition.id,
        onSuccess: () => {
          setOpenWarningCard(false);
          showMessage({ content: 'Позиция удалена из карты оснащения.', icon: <BinIcon className="icon-bin-dark-grey" /> });
        },
      });
    }
  };

  const addGoodsToBasket = (id: number) => {
    if (currentBasket) {
      const newBasketGoods = [
        ...currentBasket.goods.map((basketGoods) => ({ goodId: basketGoods.goodId, count: basketGoods.count })),
        { goodId: id, count: DEFAULT_GOODS_COUNT },
      ];

      updateBasket({
        id: currentBasket.id,
        goods: newBasketGoods,
        onSuccess: () => {
          updateGoodsListGoodsInBasket({ id, goodInBasket: true });
          showSuccessMessage(EMessage.GoodsAddedToCart);
          getUserStatistics();
        },
      });
    }
  };

  if (!workspacePosition) {
    return null;
  }

  return (
    <>
      <Drawer
        open={openPositionCard}
        rootClassName="redesign drawer"
        width={800}
        onClose={() => setOpenPositionCard(false)}
        classNames={{ header: 'drawer__header-with-extra' }}
        extra={
          editable && (
            <Dropdown
              overlayClassName="redesign"
              menu={{
                items: [
                  { key: 'edit', label: 'Редактировать позицию', onClick: onEditClick },
                  { type: 'divider' },
                  { key: 'copy', label: 'Дублировать позицию', onClick: onCopyClick },
                  { type: 'divider' },
                  { key: 'del ete', label: 'Удалить из карты', onClick: onDeleteClick, className: 'red' },
                ],
              }}
              placement="bottomRight"
            >
              <Button className="button-circle secondary" icon={<OptionsIcon className="icon-options" />} />
            </Dropdown>
          )
        }
      >
        <Spin spinning={propertyListLoading || basketLoading} indicator={<SpinIndicator />}>
          <div className="text-h1-drawers mb-52">{workspacePosition.categoryName}</div>

          <div className="mb-32">
            <InventoryCount count={workspacePosition.inventoryCount} limit={workspacePosition.inventoryLimit} />
          </div>

          <div className="mb-52">
            <WorkspacePositionInventoryLimit
              content={`Количество на сотрудника: ${countFormatter(workspacePosition.inventoryLimit)}`}
            />
          </div>

          <div className="workspace-position-card__block">
            <div className="workspace-position-card__block-header">
              <span className="text-h4">Характеристики</span>

              {workspacePosition.properties.length > ITEMS_FOR_VIEW_LIMIT_4 && (
                <Button className="button-text" onClick={() => setShowAllProperties((prev) => !prev)}>
                  {showAllProperties ? 'Свернуть' : 'Показать все'}
                </Button>
              )}
            </div>

            <div className="workspace-position-card__block-list mb-72">
              {propertiesForView?.map(({ propertyId, propertyName, presetType, unitOfMeasurement, values, range }, index) => {
                const unit = unitOfMeasurement ? ` ${unitOfMeasurement}` : '';

                if (presetType === EPresetType.Range) {
                  return (
                    <div key={propertyId} className="workspace-position-card__block-list-row">
                      <span className="text-body color-dark-grey">{propertyName}</span>

                      <span className="text-body">{`${getPresetRangeValue(range)}${unit}`}</span>
                    </div>
                  );
                }

                return (
                  <div key={`${propertyId}-${index}`} className="workspace-position-card__block-list-row">
                    <span className="text-body color-dark-grey">{propertyName}</span>

                    <span className="text-body">{values?.map((value) => `${value}${unit}`).join(', ')}</span>
                  </div>
                );
              })}
            </div>
          </div>

          {!!goodsList.length && (
            <div className="mb-172">
              <div className="text-h4 mb-16">Товары из каталога</div>

              <div className="workspace-position-card__goods-list">
                {goodsList.map((goods) => {
                  const deliveryTime = calculateDeliveryTime(goods.remains, DEFAULT_GOODS_COUNT);

                  return (
                    <div key={goods.id} className="workspace-position-card__goods-list-item">
                      <Image size={120} src={goods.image} className="mb-32" />

                      <div className="text-accent mb-12">{priceFormatter(goods.price)}</div>

                      <div className="text-controls-accent mb-12">{goods.name}</div>

                      <div className="workspace-position-card__goods-list-item-codes mb-32">
                        {goods.sellerCode && <span className="text-tag color-dark-grey">{goods.sellerCode}</span>}

                        {goods.buyerCode && <span className="text-tag color-bright-green">{goods.buyerCode}</span>}
                      </div>

                      {goods.goodInBasket ? (
                        <Button
                          className="button-s success"
                          icon={<CartIcon className="icon-cart-white" />}
                          onClick={() => navigate(ERoute.Basket)}
                        >
                          Перейти в корзину
                        </Button>
                      ) : (
                        <Button
                          className="button-s primary"
                          icon={<CartIcon className="icon-cart-white" />}
                          onClick={() => addGoodsToBasket(goods.id)}
                        >
                          {deliveryTime}
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Spin>
      </Drawer>

      <WarningDrawer
        open={openWarningCard}
        content="Вы уверены, что хотите удалить позицию из карты оснащения?"
        subtitle="Отменить данное действие будет невозможно."
        confirmBtnTitle="Удалить"
        loading={workspacePositionLoading}
        onClose={() => {
          setOpenWarningCard(false);
          setOpenPositionCard(true);
        }}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
};

const mapState = (state: RootState) => ({
  propertyListLoading: state.propertyListState.loading,
  goodsList: state.goodsListState.data,
  workspacePosition: state.workspacePositionState.data,
  workspacePositionLoading: state.workspacePositionState.loading,
  currentBasket: state.basketState.currentBasket,
  basketLoading: state.basketState.loading,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getPropertyListByCategoryId: dispatch.propertyListState.getPropertyListByCategoryId,
  updateGoodsListGoodsInBasket: dispatch.goodsListState.updateGoodsListGoodsInBasket,
  deleteWorkspacePosition: dispatch.workspacePositionState.deleteWorkspacePosition,
  updateBasket: dispatch.basketState.updateBasket,
  getUserStatistics: dispatch.statisticsState.getUserStatistics,
});

export const WorkspacePositionCard = connect(mapState, mapDispatch)(Component);
